import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { getConfigApi } from "@/request/api/home";

export default new Vuex.Store({
  state: {
    orgId: 0, // 公司id,
    phoneDesc: "",
    region: "CA",
    menuShow: false,
    logo: "",
    icon: "",
    footerLogo: "",
    icpRecordNumber: "",
    networkRecordNumber: "",
    networkRecordNumber2: "",
    copyright: "",
    qrCode: "",
  },
  getters: {},
  mutations: {
    initConfig(state, payload) {
      state.orgId = payload.orgId;
      state.phoneDesc = payload.phoneDesc;
      state.logo = payload.logo;
      state.icon = payload.icon;
      state.footerLogo = payload.footerImage;
      state.icpRecordNumber = payload.icpRecordNumber;
      state.networkRecordNumber = payload.networkRecordNumber;
      state.copyright = payload.copyright;
      state.qrcodeImage = payload.customerServiceQrCode;
      if (payload.networkRecordNumber) {
        let str = "";
        payload.networkRecordNumber.split("").forEach((e) => {
          if (String(+e) !== "NaN" && e !== " ") {
            str += String(+e);
          }
        });
        state.networkRecordNumber2 = str;
      }
    },
    regionSet(state, payload) {
      if (payload) {
        state.region = payload;
      }
    },
    menuShowChange(state, payload) {
      state.menuShow = payload;
    },
  },
  actions: {
    GETCONFIG({ commit }) {
      return new Promise((resolve, reject) => {
        getConfigApi()
          .then((res) => {
            if (res.code === 200) {
              const { textColor, footerColor, icon, title } = res.data;
              // 案例客户底色
              let trans = "";
              if (textColor && textColor.slice(0, 4) === "rgba") {
                trans = `${textColor.slice(
                  0,
                  textColor.lastIndexOf(",")
                )}, 0.68)`;
              } else {
                const regExp = new RegExp("\\((.*?)\\)");
                const matchResult = textColor.match(regExp);
                trans = `rgba(${matchResult[1]},0.68)`;
                // trans = `rgba(${textColor.match(/(?<=\()(.+?)(?=\))/)[0]},0.68)`;
              }
              const body = document.querySelector("body");
              body.style.setProperty("--textColor", textColor || "#0436A2");
              body.style.setProperty("--footerColor", footerColor || "#012169");
              body.style.setProperty("--trans", trans);
              commit("initConfig", res.data);
              localStorage.setItem("configData", JSON.stringify(res.data));

              // 动态设置标签页icon
              if (icon) {
                let $icon = icon;
                let $favicon = document.querySelector('link[rel="icon"]');
                $favicon = document.createElement("link");
                $favicon.rel = "icon";
                $favicon.href = $icon;
                document.head.appendChild($favicon);
              }

              // 动态设置标签title
              let $title = document.querySelector("title");
              if (title) {
                $title.innerText = title;
              } else {
                $title.innerText = "大脚出国-移民加拿大 官方网站";
              }
            }
            resolve(res);
          })
          .catch((err) => {
            resolve(err);
          });
      });
    },
  },
  modules: {},
});
