<template>
  <div class="footer">
    <!-- 爱舞功 -->
    <!-- <div class="nav_list" v-if="$store.state.orgId == 1">
      <div class="first_item" v-for="firstItem in data" :key="firstItem.directoryNo">
        <div class="title one-t">
          <span v-if="!firstItem.link">{{ firstItem.name }}</span>
          <a v-else :href="firstItem.link" target="_blank">{{ firstItem.name }}</a>
        </div>
        <div class="list awg-list" v-if="firstItem.children.length">
          <div class="item" v-for="(item, index) in firstItem.children" :key="item.directoryNo">
            <span class="one-t" v-if="!item.link">{{ item.name }}</span>
            <a class="one-t" v-else :href="item.link" target="_blank">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div> -->
    <!-- 坚果 -->
    <div class="nav_list jg-nav_list">
      <div class="first_item jg-first_item" v-for="firstItem in data" :key="firstItem.directoryNo">
        <div class="title one-t">
          <span v-if="!firstItem.link">{{ firstItem.name }}</span>
          <a v-else :href="firstItem.link" target="_blank">{{ firstItem.name }}</a>
        </div>
        <div class="list" v-if="firstItem.children.length">
          <div class="item" v-for="(item, index) in firstItem.children" :key="item.directoryNo">
            <span class="one-t" v-if="!item.link">{{ item.name }}</span>
            <a class="one-t" v-else :href="item.link" target="_blank">{{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="line"></div>
    <div class="logo-img flexbox middle center">
      <img v-if="$store.state.footerLogo" :src="$store.state.footerLogo" alt="">
    </div>
    <div class="flexbox vertical middle" @click="beian">
      <span class="footer-text" style="max-width: 60vw;text-align: center;">
        <span v-if="$store.state.icpRecordNumber">{{ $store.state.icpRecordNumber }}</span>
        <span v-if="$store.state.copyright" style="margin-left: 5px;">{{ $store.state.copyright || '' }}</span>
      </span>
    </div>
    <div v-if="$store.state.networkRecordNumber" class="flexbox center center" style="margin-top: .04rem;"
      @click="gongan">
      <span class="footer-text">{{ $store.state.networkRecordNumber }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['data'],
  data() {
    return {
      mediaArr: [
        require('../../assets/images/TikTok.png'),
        require('../../assets/images/camera.png'),
        require('../../assets/images/Zhihu.png'),
        require('../../assets/images/Xiaohongshu.png'),
      ]
    }
  },
  methods: {
    beian() {
      if(this.$store.state.icpRecordNumber){
        window.open("https://beian.miit.gov.cn/#/Integrated/index");
      }
    },
    gongan() {
      window.open(
        `http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=${this.$store.state.networkRecordNumber2 || ''}`
      );
    },
  },
}
</script>
<style lang="less" scoped>
.footer {
  background-color: var(--footerColor);
  padding: .24rem .12rem 0.15rem;

  .nav_list {
    width: 100%;
  }

  .first_item {
    margin-bottom: .4rem;
    width: 50%;
    display: inline-block;
    vertical-align: top;

    .title {
      color: #fff;
      font-weight: 500;
      font-size: .16rem;
      line-height: .24rem;

      a {
        color: #fff;
      }
    }

    .item {
      display: flex;
      flex-direction: column;

      a,
      span {
        margin-top: .16rem;
        font-size: .12rem;
        line-height: .2rem;
        display: inline-block;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    &:first-of-type {
      display: block;
      width: 100%;

      .list {
        width: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    &:nth-of-type(3),
    &:nth-of-type(5) {
      width: 40%;
      margin-left: 10%;
    }
  }

  .line {
    height: 1px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 1px;
  }

  .logo-img {
    margin: .15rem 0;

    img {
      // width: 1.6rem;
      // height: .44rem;
      max-width: 1.6rem;
      max-height: .44rem;
    }
  }

  .footer-text {
    font-size: .12rem;
    line-height: .2rem;
    color: rgba(255, 255, 255, 0.6);
  }
}

.awg-list {
  flex-wrap: wrap !important;
  max-height: 1.5rem !important;
  align-content: space-between !important;
}

.jg-nav_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.jg-first_item {
  width: 48% !important;
  margin-left: 0 !important;
}
</style>